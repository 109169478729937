<!--新闻动态-->
<template>
  <div style="padding: 2vh 11vw;">
    <div class="content">
      <div class="addressDetail">
        <div>
          <p>
            地址
          </p>
          <p>
            福建省厦门市思明区塔埔东路171号楼14层
          </p>
        </div>
        <div>
          <p>
            电话
          </p>
          <p>
            0592-2577555
          </p>
        </div>
        <div>
          <p>
            传真
          </p>
          <p>
            0592-2577588
          </p>
        </div>
        <div>
          <p>
            邮箱
          </p>
          <p>
            mlf@mlfworldgroup.com
          </p>
        </div>
      </div>
      <div class="map">
        <el-amap ref="map" class="amap-box" :zoom="zoom" :amap-manager="amapManager"  :events="events " :plugins="plugins" :center="center" id="container" style="width:50vw;height:50vh;">
          <el-amap-marker 
          :position="center"
        ></el-amap-marker>
        </el-amap>
      </div>
    </div>
  </div>
</template>

<!-- <script type="text/javascript" src="https://webapi.amap.com/maps?v=1.4.15&key=03b81d04872a18fb5db2528a8ac4f5b8"></script> -->
<script>
import { AMapManager , lazyAMapApiLoaderInstance } from 'vue-amap';
let amapManager = new AMapManager();
export default {
  name: "contactme",
  components: {},
  data() {
    const _this = this;
    return {
      amapManager,
      center:[118.194313,24.49262],
      map:null,
      plugins:['MapType'],
      zoom:18,
      events:{
        init(){
          lazyAMapApiLoaderInstance.load().then(()=>{
            _this.initMap();
          })
          
        }
      }
    };
  },
  mounted () {
    
    // lazyAMapApiLoaderInstance.load().then(()=>{
    //   this.map = new AMap.Map('container',{
    //     center:[116.404765,39.918052],
    //     zoom:this.zoom,
    //   })
    // })
  },
  methods: {
    initMap(){
    },
  }
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
}
.addressDetail {
  width:40%;
  div {
    p:nth-child(1) {
      font-size: 0.8rem;
      color: #6666;
    }
    p:nth-child(2) {
      font-size: 0.9rem;
    }
  }
}
</style>
